<script>
import Layout from "@/router/layouts/exchange";
import { VclList } from "vue-content-loading";
import { api } from "@/state/services";


export default {
  locales: {
    pt: {
      'Orders': 'Pedidos',
      'Pair': 'Par',
      'Status': 'Estado',
      'Created in': 'Criado em',
      'Type': 'Tipo',
      'Amount': 'Quantidade',
      'Unit price': 'Preço unitário',
      'Total executed': 'Total executado',
      'Remaining total': 'Total restante',
      'Sales order - Executions': 'Ordem de venda - Execuções',
      'All': 'Todos',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',

      'buy': 'compra',
      'sell': 'venda',
      'pending': 'pendente',
      'executed': 'executado',
      'canceled': 'cancelado',
    },
    es: {
      'Orders': 'Pedidos',
      'Pair': 'Par',
      'Status': 'Estado',
      'Created in': 'Criado em',
      'Type': 'Tipo',
      'Amount': 'Quantidade',
      'Unit price': 'Preço unitário',
      'Total executed': 'Total executado',
      'Remaining total': 'Total restante',
      'Sales order - Executions': 'Ordem de venda - Execuções',
      'All': 'Todos',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',

      'buy': 'compra',
      'sell': 'venda',
      'pending': 'pendente',
      'executed': 'executado',
      'canceled': 'cancelado',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      filter: {
        pair: {
          value: 'omcbrl',
          options: {
            omcbrl: 'OMC/BRL',
          }
        },
        type: {
          value: 'all',
          options: {
            all: 'Todos',
            buy: 'Compra',
            sell: 'Venda',
          }
        },
        status: {
          value: 'pending',
          options: {
            pending: 'Pendente',
            executed: 'Executado',
            canceled: 'Cancelado',
          }
        }
      },

      page: 1,
      pages: 0,

      table: {
        heade: [
          'Created in', 'Type', 'Amount', 'Unit price', 'Total executed', 'Remaining total', '%', 'Status'
        ],
        body: [],
        modal: false,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getOrders() {
      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('exchange/orders?pair=' + this.filter.pair.value + '&type=' + this.filter.type.value + '&status=' + this.filter.status.value)
        .then((response) => {
          if ( response.data.status == 'success') {
            this.table.body = response.data.list

            this.table.empty = false
            this.table.loading = false
          } else {
            this.table.empty = true
            this.table.loading = false
          }
        })
        .catch(error => {
          this.table.errored = error
          this.table.empty = false
          this.table.loading = false
        })
    }
  },
  mounted() {
    this.getOrders()
  },
  watch: {
    page: function() {
      this.getOrders();
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0">{{ t('Orders') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex">
            <div class="mr-3">
              <b-input-group :prepend="t('Pair')">
                <b-form-select v-on:change="getOrders()" v-model="filter.pair.value" :options="filter.pair.options"></b-form-select>
              </b-input-group>
            </div>
            <div class="mr-3">
              <b-input-group :prepend="t('Type')">
                <b-form-select v-on:change="getOrders()" v-model="filter.type.value" :options="filter.type.options"></b-form-select>
              </b-input-group>
            </div>
            <div class="mr-3">
              <b-input-group :prepend="t('Status')">
                <b-form-select v-on:change="getOrders()" v-model="filter.status.value" :options="filter.status.options"></b-form-select>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap table-striped">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td>{{ td.date }}</td>
                    <td class="text-uppercase">{{ t(td.type) }}</td>
                    <td>{{ td.amount }}</td>
                    <td>{{ td.price | currency }}</td>
                    <td>{{ td.executed }}</td>
                    <td>{{ td.remaining }}</td>
                    <td>{{ td.percent }}%</td>
                    <td class="text-uppercase">{{ t(td.status) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <paginate
      v-if="!table.errored && !table.empty && pages > 1"
      v-model="page"
      :page-count="pages"
      :page-range="3"
      :margin-pages="2"
      prev-text="<i class='mdi mdi-chevron-left'></i>"
      next-text="<i class='mdi mdi-chevron-right'></i>"
      :container-class="'pagination pagination-rounded justify-content-center mt-4'"
      :page-class="'page-item'"
      :page-link-class="'page-link'"
      :prev-link-class="'page-link'"
      :next-link-class="'page-link'"
      :break-view-link-class="'page-link'">
    </paginate>
  </Layout>
</template>